import { DateTime } from "luxon";

function normalizePeriod(startDate, endDate) {
  const a = DateTime.fromJSDate(startDate).startOf("day");
  const b = DateTime.fromJSDate(endDate).endOf("day");

  return { startDate: a.toJSDate(), endDate: b.toJSDate() };
}

function previousPeriod(startDate, endDate) {
  const period = normalizePeriod(startDate, endDate);

  const a = DateTime.fromJSDate(period.startDate).startOf("day");
  const b = DateTime.fromJSDate(period.endDate).endOf("day");

  const diff = b.diff(a, "days");

  return {
    startDate: a.minus(diff + 1).toJSDate(),
    endDate: a.minus(1).toJSDate()
  };
}

function toISODate(a) {
  return DateTime.fromJSDate(a).toISODate();
}

export { normalizePeriod, previousPeriod, toISODate };
