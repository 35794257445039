import React, { useState, useEffect } from "react";
import { useAuth } from "./auth-context";
import { getUser } from "../utils/auth-client";

const UserContext = React.createContext();

function UserProvider(props) {
  useAuth();
  const [userData, setUserData] = useState({
    platforms: [],
    loadComplete: false
  });

  useEffect(() => {
    const fetchUser = async () => {
      const data = await getUser();

      if (data) {
        setUserData({ ...userData, ...data, loadComplete: true });
      }
    };

    if (!userData.loadComplete) {
      fetchUser();
    }
  });

  return <UserContext.Provider value={userData} {...props} />;
}

function useUser() {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserProvider`);
  }
  return context;
}

export { UserProvider, useUser };
