import axios from "axios";

async function client(endpoint, { body, ...customConfig } = {}) {
  //   const token = window.localStorage.getItem("__mdcustomerdash_token__");
  const headers = { "content-type": "application/json" };
  //   if (token) {
  //     headers.Authorization = `Bearer ${token}`;
  //   }

  const config = {
    method: body ? "POST" : "GET",
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };
  if (body) {
    config.data = JSON.stringify(body);
  }

  return await axios({ url: `${endpoint}`, ...config }).then((r) => r.data);
}

export default client;
