import { getUser } from "./auth-client";
// import { readForUser } from "./list-items-client";

async function bootstrapAppData() {
  const data = await getUser();
  if (!data) {
    return null;
  }
  const { listItems } = []; //await readForUser(user.id);
  return {
    ...data,
    listItems
  };
}

export { bootstrapAppData };
