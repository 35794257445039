import React from "react";
import { useAuth } from "./context/auth-context";
import { FullPageSpinner } from "./components/lib";
import { Toaster } from "react-hot-toast";

const loadAuthenticatedApp = () => import("./authenticated-app");
const AuthenticatedApp = React.lazy(loadAuthenticatedApp);
const UnauthenticatedApp = React.lazy(() => import("./unauthenticated-app"));

function App() {
  document.title = "MD dashboard";

  const { isLoggedIn } = useAuth();
  console.log("APP isLoggedIn", isLoggedIn);
  // pre-load the authenticated side in the background while the user's
  // filling out the login form.
  React.useEffect(() => {
    loadAuthenticatedApp();
  }, []);
  return (
    <React.StrictMode>
      <React.Suspense fallback={<FullPageSpinner />}>
        {isLoggedIn ? <AuthenticatedApp /> : <UnauthenticatedApp />}
        <Toaster />
      </React.Suspense>
    </React.StrictMode>
  );
}

export default App;
