import React, { useState, useEffect } from "react";
import { useUser } from "./user-context";
import { normalizePeriod } from "../utils/time";

const FilterContext = React.createContext();

function FilterProvider(props) {
  const today = new Date();
  const { startDate, endDate } = normalizePeriod(
    new Date(new Date().setDate(today.getDate() - 90)),
    today
  );

  const user = useUser();
  const [data, setData] = useState({
    startDate: startDate,
    endDate: endDate,
    platforms: [],
  });

  useEffect(() => {
    if (data.platforms.length === 0 && user.platforms.length !== 0) {
      setData({
        ...data,
        platforms: user.platforms,
      });
    }
  });

  return <FilterContext.Provider value={[data, setData]} {...props} />;
}

function useFilter() {
  const context = React.useContext(FilterContext);
  if (context === undefined) {
    throw new Error(`useFilter must be used within a FilterProvider`);
  }
  return context;
}

export { FilterProvider, useFilter };
