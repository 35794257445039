import client from "./api-client";

const localStorageKey = "__mdcustomerdash_token__";

function handleUserResponse(data) {
  console.log("handleUserResponse:", data);
  window.localStorage.setItem(localStorageKey, data.token);
  return data.user;
}

function getUser() {
  const token = getToken();
  if (!token) {
    return Promise.resolve(null);
  }

  return client("api/me").catch((error) => {
    logout();
    return Promise.reject(error);
  });
}

function login({ email, password }) {
  return client("api/login", { body: { email, password } }).then(
    handleUserResponse,
    (err) => {
      console.log(err);
      return Promise.reject("Invalid credentials");
    }
  );
}

// function register({ username, password }) {
//   return client("register", { body: { username, password } }).then(
//     handleUserResponse
//   );
// }

function logout() {
  window.localStorage.removeItem(localStorageKey);
  client("api/logout", { method: "DELETE" }).then(window.location.replace("/"));
}

function getToken() {
  return window.localStorage.getItem(localStorageKey);
}

export { login, logout, getToken, getUser };
