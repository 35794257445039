import React, { useState } from "react";
import { useAsync } from "react-async";
import { bootstrapAppData } from "../utils/bootstrap";
import * as authClient from "../utils/auth-client";
import { FullPageSpinner } from "../components/lib";

const AuthContext = React.createContext();

function AuthProvider(props) {
  const [firstAttemptFinished, setFirstAttemptFinished] = useState(false);

  const loginCheck = () => {
    const token = authClient.getToken();
    return !!token;
  };

  const isLoggedIn = loginCheck();

  const { error, isRejected, isPending, isSettled, reload } = useAsync({
    promiseFn: bootstrapAppData,
  });

  React.useLayoutEffect(() => {
    if (isSettled) {
      setFirstAttemptFinished(true);
    }
  }, [isSettled]);

  if (!firstAttemptFinished) {
    if (isPending) {
      return <FullPageSpinner />;
    }
    if (isRejected) {
      return (
        <div css={{ color: "red" }}>
          <p>Uh oh... There's a problem. Try refreshing the app.</p>
          <pre>{error.message}</pre>
        </div>
      );
    }
  }

  const login = (form) => authClient.login(form).then(reload);
  const logout = () => authClient.logout().then(reload);

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout }} {...props} />
  );
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}

export { AuthProvider, useAuth };
